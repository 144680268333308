<template>
  <div>
    <vx-card class="mb-base no-shadow">
      <vs-button v-if="showForm" @click="onCreate">{{ $t("Back") }}</vs-button>

      <transition name="fade" mode="out-in">
        <div class="mb-base">
          <data-table
            class="datatableCustomers"
            v-if="!showForm"
            :data="tableData"
            :options="tableOptions"
            :exportRequest="exportRequest"
            @sort="onSort"
            @search="onSearch"
            @change-page="onTablePageChange"
            @per-page-change="onPerPageChange"
            @date-range-search="onDateRangeSearch"
            @date-range-clear="onDateRangeClear"
          />
        </div>
      </transition>
    </vx-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DataTable from "@/modules/Shared/Components/table/DataTable.vue";
import CustomerForm from "@/modules/Shops/Pages/forms/CustomerForm.vue";
import notifications from "@/modules/Shared/Mixins/notifications.js";
import table_functions from "@/modules/Shared/Mixins/table_functions.js";

export default {
  name: "Customers",

  mixins: [notifications, table_functions],

  components: {
    DataTable,
    CustomerForm,
  },

  props: {
    onlyForm: { type: Boolean, default: false },
  },

  data() {
    return {
      showForm: false,
      formData: {},
      defaultSort: "-id",
      exportRequest: {},
      exportPayload: {}
    };
  },

  created() {
    this.exportPayload.sort = this.defaultSort;

    this.exportRequest = {
      request: `resorts/${this.$route.params.uuid}/customers_export`,
      payload: this.exportPayload
    };
    this.$store.commit("shops/customers/RESET_RESOURCE");
  },

  computed: {
    ...mapGetters("shops/customers", {
      customersList: "getAll",
      customerById: "getById",
      totalPages: "getTotalPages",
      currentPage: "getCurrentPage",
      perPage: "getPerPage",
    }),

    tableData() {
      return this.customersList.map(this.customerToTableData);
    },

    tableOptions() {
      return {
        actions: {
          create: false,
          update: false,
          delete: false,
        },
        pagination: {
          currentPage: this.currentPage,
          totalPages: this.totalPages,
          itemsPerPage: this.perPage,
        },
        filters: {
          dateRange: true,
        },
      };
    },
  },

  methods: {
    ...mapActions("shops/customers", {
      loadData: "load",
    }),

    customerToTableData(customer) {
      return {
        email: customer.email,
        total_orders: customer.orders,
        keycards: this.keycardListToString(customer.keycards) || "---"
      };
    },

    keycardListToString(list) {
      return list.reduce((prev, k) => prev + `${k.number}<br />`, "");
    },

    default() {
      return {
        id: 0,
        email: "",
        password: "",
        validated_at: "",
        validated_ip: "",
        registered_ip: "",
        logins: [],
        keycards: [],
        people: [],
        carts: [],
        orders: [],
        resorts: [],
        created_at: "",
        updated_at: "",
      }
    }
  }
};
</script>
<style>
</style>
