var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "customer-form" },
    [
      _c("div", { staticClass: "vx-row mb-6" }, [
        _c("div", { staticClass: "vx-col w-1/2" }, [
          _c(
            "label",
            { staticClass: "vs-input--label" },
            [
              _vm._v(" " + _vm._s(_vm.$t("Active")) + " "),
              _c("vs-switch", {
                staticClass: "mt-3",
                attrs: { type: "boolean" },
                model: {
                  value: _vm.value.active,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "active", $$v)
                  },
                  expression: "value.active"
                }
              })
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "vx-row mb-6" }, [
        _c(
          "div",
          { staticClass: "vx-col w-1/5" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("Email")))
            ]),
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              staticClass: "w-full",
              attrs: {
                name: "email",
                danger: _vm.errors.has("email"),
                "danger-text": _vm.$t("Email is required")
              },
              model: {
                value: _vm.value.email,
                callback: function($$v) {
                  _vm.$set(_vm.value, "email", $$v)
                },
                expression: "value.email"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-1/5" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("Password")))
            ]),
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              staticClass: "w-full",
              attrs: {
                name: "password",
                danger: _vm.errors.has("password"),
                "danger-text": _vm.$t("Password is required")
              },
              model: {
                value: _vm.value.password,
                callback: function($$v) {
                  _vm.$set(_vm.value, "password", $$v)
                },
                expression: "value.password"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-1/5" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("Token")))
            ]),
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              staticClass: "w-full",
              attrs: {
                name: "token",
                danger: _vm.errors.has("token"),
                "danger-text": _vm.$t("Token is required")
              },
              model: {
                value: _vm.value.token,
                callback: function($$v) {
                  _vm.$set(_vm.value, "token", $$v)
                },
                expression: "value.token"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-1/5" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("Validated at")))
            ]),
            _c("date-picker", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              class: { "danger-border": _vm.errors.has("validated_at") },
              attrs: {
                danger: _vm.errors.has("validated_at"),
                inline: false,
                placeholder: "01/01/1970",
                "danger-text": _vm.$t(
                  "shops.errors.validate_at_date_is_required"
                ),
                name: "validate_at"
              },
              model: {
                value: _vm.value.validated_at,
                callback: function($$v) {
                  _vm.$set(_vm.value, "validated_at", $$v)
                },
                expression: "value.validated_at"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-1/5" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("Registered IP")))
            ]),
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              staticClass: "w-full",
              attrs: {
                name: "registered_ip",
                danger: _vm.errors.has("registered_ip"),
                "danger-text": _vm.$t("Registered IP is required")
              },
              model: {
                value: _vm.value.registered_ip,
                callback: function($$v) {
                  _vm.$set(_vm.value, "registered_ip", $$v)
                },
                expression: "value.registered_ip"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-1/5" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("Validated IP")))
            ]),
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              staticClass: "w-full",
              attrs: {
                name: "validated_ip",
                danger: _vm.errors.has("validated_ip"),
                "danger-text": _vm.$t("Validated IP is required")
              },
              model: {
                value: _vm.value.validated_ip,
                callback: function($$v) {
                  _vm.$set(_vm.value, "validated_ip", $$v)
                },
                expression: "value.validated_ip"
              }
            })
          ],
          1
        )
      ]),
      _c("vs-divider"),
      _c(
        "vs-button",
        {
          staticClass: "mt-base",
          attrs: { type: "filled" },
          on: { click: _vm.submit }
        },
        [_vm._v(_vm._s(_vm.$t("Save")))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }