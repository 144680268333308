<template>
  <div class="customer-form">
    <div class="vx-row mb-6">
      <div class="vx-col w-1/2">
        <label class="vs-input--label">
          {{ $t("Active") }}
          <vs-switch class="mt-3" type="boolean" v-model="value.active" />
        </label>
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col w-1/5">
        <label class="vs-input--label">{{ $t("Email") }}</label>
        <vs-input
          v-model="value.email"
          v-validate="'required'"
          class="w-full"
          name="email"
          :danger="errors.has('email')"
          :danger-text="$t('Email is required')"
        />
      </div>

      <div class="vx-col w-1/5">
        <label class="vs-input--label">{{ $t("Password") }}</label>
        <vs-input
          v-model="value.password"
          v-validate="'required'"
          class="w-full"
          name="password"
          :danger="errors.has('password')"
          :danger-text="$t('Password is required')"
        />
      </div>

      <div class="vx-col w-1/5">
        <label class="vs-input--label">{{ $t("Token") }}</label>
        <vs-input
          v-model="value.token"
          v-validate="'required'"
          class="w-full"
          name="token"
          :danger="errors.has('token')"
          :danger-text="$t('Token is required')"
        />
      </div>

      <div class="vx-col w-1/5">
        <label class="vs-input--label">{{ $t("Validated at") }}</label>
        <date-picker
          :class="{ 'danger-border': errors.has('validated_at') }"
          :danger="errors.has('validated_at')"
          :inline="false"
          placeholder="01/01/1970"
          v-validate="'required'"
          :danger-text="$t('shops.errors.validate_at_date_is_required')"
          v-model="value.validated_at"
          name="validate_at"
        ></date-picker>
      </div>

      <div class="vx-col w-1/5">
        <label class="vs-input--label">{{ $t("Registered IP") }}</label>
        <vs-input
          v-model="value.registered_ip"
          v-validate="'required'"
          class="w-full"
          name="registered_ip"
          :danger="errors.has('registered_ip')"
          :danger-text="$t('Registered IP is required')"
        />
      </div>

      <div class="vx-col w-1/5">
        <label class="vs-input--label">{{ $t("Validated IP") }}</label>
        <vs-input
          v-model="value.validated_ip"
          v-validate="'required'"
          class="w-full"
          name="validated_ip"
          :danger="errors.has('validated_ip')"
          :danger-text="$t('Validated IP is required')"
        />
      </div>

      <!-- <div class="vx-col w-1/5">
        <label class="vs-input--label">{{ $t("Logins") }}</label>
        <vs-select class="w-full select-large" name="catalog" multiple v-model="logins" placeholder="Select logins">
          <vs-select-item
            class="w-full"
            :key="index"
            :value="item.value"
            :text="item.text"
            v-for="(item, index) in valueLogins"
            :disabled="valueLogins.length === 0"
          />
        </vs-select>
      </div>

      <div class="vx-col w-1/5">
        <label class="vs-input--label">{{ $t("Keycards") }}</label>
        <vs-select class="w-full select-large" name="catalog" multiple v-model="keycards" placeholder="Select keycards">
          <vs-select-item
            class="w-full"
            :key="index"
            :value="item.value"
            :text="item.text"
            v-for="(item, index) in valueKeycards"
            :disabled="valueKeycards.length === 0"
          />
        </vs-select>
      </div>

      <div class="vx-col w-1/5">
        <label class="vs-input--label">{{ $t("People") }}</label>
        <vs-select class="w-full select-large" name="catalog" multiple v-model="people" placeholder="Select people">
          <vs-select-item
            class="w-full"
            :key="index"
            :value="item.value"
            :text="item.text"
            v-for="(item, index) in valuePeople"
            :disabled="valuePeople.length === 0"
          />
        </vs-select>
      </div>

      <div class="vx-col w-1/5">
        <label class="vs-input--label">{{ $t("Carts") }}</label>
        <vs-select class="w-full select-large" name="catalog" multiple v-model="carts" placeholder="Select carts">
          <vs-select-item
            class="w-full"
            :key="index"
            :value="item.value"
            :text="item.text"
            v-for="(item, index) in valueCarts"
            :disabled="valueCarts.length === 0"
          />
        </vs-select>
      </div>

      <div class="vx-col w-1/5">
        <label class="vs-input--label">{{ $t("Orders") }}</label>
        <vs-select class="w-full select-large" name="catalog" multiple v-model="orders" placeholder="Select orders">
          <vs-select-item
            class="w-full"
            :key="index"
            :value="item.value"
            :text="item.text"
            v-for="(item, index) in valueOrders"
            :disabled="valueOrders.length === 0"
          />
        </vs-select>
      </div>

      <div class="vx-col w-1/5">
        <label class="vs-input--label">{{ $t("Resorts") }}</label>
        <vs-select class="w-full select-large" name="catalog" multiple v-model="resorts" placeholder="Select resorts">
          <vs-select-item
            class="w-full"
            :key="index"
            :value="item.value"
            :text="item.text"
            v-for="(item, index) in valueResorts"
            :disabled="valueResorts.length === 0"
          />
        </vs-select>
      </div> -->
    </div>

    <vs-divider />

    <vs-button @click="submit" class="mt-base" type="filled">{{ $t("Save" ) }}</vs-button>
  </div>
</template>

<script>
import DatePicker from "vuejs-datepicker";

const defaultCustomer = () => ({
  id: 0,
  email: "",
  password: "",
  validated_at: "",
  registered_ip: "",
  validated_ip: "",
  logins: [],
  keycards: [],
  people: [],
  carts: [],
  orders: [],
  resorts: [],
  created_at: "",
  updated_at: ""
});

export default {
  name: "customer-form",

  components: {
    DatePicker
  },

  props: {
    value: { type: Object, default: () => defaultCustomer() }
  },

  data() {
    return {
      logins: [],
      keycards: [],
      people: [],
      carts: [],
      orders: [],
      resorts: []
    };
  },

  created() {},

  computed: {
    valueLogins() {
      return this.value.logins.map(this.loginToForm);
    },

    valueKeycards() {
      return this.value.keycards.map(this.keycardToForm);
    },

    valuePeople() {
      return this.value.people.map(this.peopleToForm);
    },

    valueCarts() {
      return this.value.carts.map(this.cartToForm);
    },

    valueOrders() {
      return this.value.carts.map(this.orderToForm);
    },

    valueResorts() {
      return this.value.resorts.map(this.resortToForm);
    }
  },

  methods: {
    loginToForm(login) {
      return {
        value: login,
        text: `${login.id} - ${login.method}`
      };
    },

    keycardToForm(keycard) {
      return {
        value: keycard,
        text: `${keycard.id} - ${keycard.number}`
      };
    },

    peopleToForm(people) {
      return {
        value: people,
        text: `${people.id} - ${people.firstname} ${people.lastname}`
      };
    },

    cartToForm(cart) {
      return {
        value: cart,
        text: `${cart.id} - ${cart.options}`
      };
    },

    orderToForm(order) {
      return {
        value: order,
        text: `${order.id} - ${order.reference}`
      };
    },

    resortToForm(resort) {
      return {
        value: resort,
        text: `${resort.id} - ${resort.name}`
      };
    },

    submit() {
      this.$emit("submit", Object.assign(this.$data, this.value));
    }
  }
};
</script>

<style>
</style>
